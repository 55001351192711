let modalsBtns = document.querySelectorAll('.js-open-modal')

if (modalsBtns !== null && modalsBtns !== undefined) {
    modalsBtns.forEach((btn) => {
        btn.addEventListener('click', function (e) {
            e.preventDefault()
            let neededModalId = this.getAttribute('data-href')

            let neededModal = document.getElementById(neededModalId)
            if (neededModal !== null && neededModal !== undefined) {
                neededModal.style.display = "flex";
            }
        })
    })
}


let modalCloseBtns = document.querySelectorAll('.js-modal-close-modal')
if (modalCloseBtns !== null && modalCloseBtns !== undefined) {
    modalCloseBtns.forEach((btn) => {
        btn.addEventListener('click', function (e) {
            e.preventDefault()

            let neededModal = this.closest('.modal-item')
            if (neededModal !== null && neededModal !== undefined) {
                neededModal.style.display = "none";
            }
        })
    })
}


let calendlyBtns = document.querySelectorAll('.js-calendly-open')
if (calendlyBtns !== null && calendlyBtns !== undefined) {
    calendlyBtns.forEach((btn) => {
        btn.addEventListener('click', function (e) {
            e.preventDefault()

            if (Calendly !== null && Calendly !== undefined) {
                let popupBackgroundColor = 'f2f2f2'
                let popupHideGDPRBanner = '1'
                let popupTextColor = '000000'
                let popupPrimaryColor = '3dbbff'
                let userId = '';
                let userEmail = '';
                let argsA1 = '';
                let name = '';
                let utm_source = '';

                if (vars !== undefined && vars !== null) {
                    userId = vars.id.trim();
                    userEmail = vars.email.trim();
                    argsA1 = vars.a1.trim();
                    name = vars.name.trim();
                    utm_source = vars.utm_source.trim();
                }

                let urlLink = this.getAttribute('href')

                let urlLinkParams = '?&hide_gdpr_banner=' + popupHideGDPRBanner + '&background_color=' + popupBackgroundColor + '&text_color=' + popupTextColor + '&primary_color=' + popupPrimaryColor + '&id=' + userId + '&email=' + userEmail + '&name=' + name + '&a1=' + argsA1 + '&utm_source=' + utm_source

                let full_link = urlLink + urlLinkParams

                Calendly.initPopupWidget({url: full_link});

            }
            return false;
        })
    })
}

let forgotPassForm = document.getElementById('forgotPassForm')
if (forgotPassForm !== null && forgotPassForm !== undefined) {
    forgotPassForm.addEventListener('submit', function (e) {
        e.preventDefault()

        let url = this.getAttribute('action')
        let formData = new FormData(this)

        let invalidFeedbackContainer = this.querySelector('.invalid-feedback')
        invalidFeedbackContainer.style.display = 'none'

        const modal1 = document.getElementById("modal1");
        const modal2 = document.getElementById("modal2");

        let button = this.querySelector('button[type=submit]')
        let buttonTextDefault = button.getAttribute('data-text-default')
        let buttonTextProcessing = button.getAttribute('data-text-processing')
        let buttonTextSuccess = button.getAttribute('data-text-success')
        let buttonTextError = button.getAttribute('data-text-error')

        let requestOptions = {
            method: 'POST',
            body: formData,
            headers: new Headers({'accept': 'application/json'}),
            redirect: 'follow'
        };

        button.textContent = buttonTextProcessing;
        button.setAttribute('disabled', true)

        fetch(url, requestOptions)
            .then(response => response.text())
            .then((result) => {
                let resp = JSON.parse(result)
                if (resp.status !== undefined && resp.status !== null && resp.status === true) {

                    button.textContent = buttonTextSuccess;

                    if (modal1 !== null) {
                        modal1.style.display = "none";
                    }
                    if (modal2 !== null) {
                        modal2.style.display = "flex";
                    }

                    setTimeout(function () {
                        button.textContent = buttonTextDefault;
                        button.removeAttribute('disabled')
                    }, 3000)

                } else {
                    invalidFeedbackContainer.innerHTML = resp.message
                    invalidFeedbackContainer.style.display = 'block'

                    button.textContent = buttonTextError;

                    setTimeout(function () {
                        button.textContent = buttonTextDefault;
                        button.removeAttribute('disabled')
                    }, 2000)
                }
            })
            .catch((errors) => {
                let resp = JSON.parse(errors)
            });
    })
}

let sendItAgainBtn = document.querySelector('.js-send-it-again')

if (sendItAgainBtn !== null && sendItAgainBtn !== undefined) {
    sendItAgainBtn.addEventListener('click', function (e) {
        e.preventDefault()

        const modal1 = document.getElementById("modal1");
        const modal2 = document.getElementById("modal2");

        if (modal2 !== null) {
            modal2.style.display = "none";
        }

        if (modal1 !== null) {
            modal1.style.display = "flex";
        }
    })
}

let disabledReportBtns = document.querySelectorAll('.report-btn-disabled')
if (disabledReportBtns !== null && disabledReportBtns !== undefined) {
    disabledReportBtns.forEach((disabledReportBtn) => {
        disabledReportBtn.addEventListener('click', function (e) {
            e.preventDefault()
        })
    })
}


async function sendAjax(url, data) {
    let csrf = $('meta[name="csrf-token"]').attr('content')

    let sendData = new FormData();

    if (data !== null && data !== undefined) {
        for (const [key, value] of Object.entries(data)) {
            sendData.append(key, value);
        }
    }
    sendData.append('_token', csrf);

    const headers = new Headers({
        "X-CSRF-Token": csrf,
        "Accept": "application/json",
        "X-Requested-With": "XMLHttpRequest"
    });

    let requestOptions = {
        headers: headers,
        method: 'POST',
        body: sendData,
        redirect: 'follow'
    };
    const response = await fetch(url, requestOptions);
    const res = await response
    const responseJson = res.json();
    return responseJson;
}

function responseAction(response) {

    let templates = response.templates

    let topicsCardsList = $(document).find('.js-topics-cards')
    if (topicsCardsList.length !== 0) {
        if (response.loadMore === true) {
            $(document).find('.js-topics-cards-items').append(templates.cards)

            if (response.currentPage === response.maxPages) {
                $(topicsCardsList).find('.load-block').hide()
            }
        } else {
            $(topicsCardsList).replaceWith(templates.cards)
        }
    }

    let topicsLinksList = $(document).find('.js-topics-links')
    if (topicsLinksList.length !== 0) {
        if (response.loadMore === true) {
            $(document).find('.js-topics-links-items').append(templates.links)

            if (response.currentPage === response.maxPages) {
                $(topicsLinksList).find('.load-block').hide()
            }
        } else {
            $(topicsLinksList).replaceWith(templates.links)
        }
    }

    $('.js-topics-container').attr('data-current-page', response.currentPage)
    $('.js-topics-container').attr('data-max-pages', response.maxPages)
}


$(document).on('click', '.btn-favourites', function (e){
    e.preventDefault()
    let thisBtn = $(this)

    let topicId = $(thisBtn).attr('data-topic-id')
    let allThisTopicCardBtns = $('.btn-favourites[data-topic-id=' + topicId + ']')

    let sendObj = {
        topic_id: topicId,
    }

    if($(thisBtn).hasClass('btn-favourites--active')){
        sendObj.adding = 0
    } else{
        sendObj.adding = 1
    }

    console.log(sendObj)

    let url = '/topic-bookmark'

    sendAjax(url, sendObj).then(response => {
        $(allThisTopicCardBtns).toggleClass('btn-favourites--active')
    })
})
